export const FORM_NAME = 'contact';

export const FormFieldNames = {
    BOT: 'botVerification',
    EMAIL: 'email',
    MESSAGE: 'message',
    NAME: 'name',
    COMPANY: 'company',
    PHONE: 'phone',
    CURRENT_PAGE: 'from-page'
};
