import React from 'react';

import "@fontsource/roboto/latin-400.css";
import "@fontsource/roboto/latin-500.css";
import "@fontsource/roboto/latin-700.css";
import "@fontsource/roboto/latin-900.css";

import { ContactContextProvider } from './src/context/Contact';

export const wrapRootElement = ({ element }) => {
    return (
        <ContactContextProvider>{element}</ContactContextProvider>
    );
};

export const onClientEntry = async () => {
    if (typeof IntersectionObserver === `undefined` || !IntersectionObserver) {
        await import(`intersection-observer`);
        IntersectionObserver.prototype.POLL_INTERVAL = 100;
    }

    // fetch polyfill for IE support
    if (typeof fetch === `undefined` || !fetch) {
        await import('whatwg-fetch');
    }

    // search param polyfill for IE support
    if (typeof URLSearchParams === `undefined` || !URLSearchParams) {
        await import('url-search-params-polyfill');
    }

    // load polyfill for non-touch safari browsers and IE. Safari does not support easing parameter of scroll to as of 1/1/2021
    if (((typeof document.body.scrollTo === 'undefined') || (
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    )) && !('ontouchstart' in window || !!navigator.msMaxTouchPoints)) {
        await import('scroll-behavior-polyfill');
    }
};

//export const onServiceWorkerUpdateReady = () => {
//    //const answer = window.confirm(
//    //    `This application has been updated. ` +
//    //    `Reload to display the latest version?`
//    //)

//    //if (answer === true) {
//        window.location.reload()
//    //}
//};