import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import SlideTransition from '@material-ui/core/Slide';

import { FormFieldNames, FORM_NAME } from '../constants/forms/contact-form';

const defaultContactState = {
    [FormFieldNames.BOT]: '',
    [FormFieldNames.EMAIL]: '',
    [FormFieldNames.MESSAGE]: '',
    [FormFieldNames.NAME]: '',
    [FormFieldNames.COMPANY]: '',
    [FormFieldNames.PHONE]: '',
};

const ContactContext = React.createContext({
    ...defaultContactState,
    errors: {},
    submitContactForm: () => {},
    updateContactState: () => {},
});

export const ContactContextProvider = ({ children }) => {
    const [contactState, setContactState] = React.useState(defaultContactState);
    const [errors, setErrors] = React.useState({});
    const [hasSubmittedForm, setHasSubmittedForm] = React.useState(false);
    const [isSubmittingForm, setIsSubmittingForm] = React.useState(false);
    const [showSuccessSnackbar, setShowSuccessSnackbar] = React.useState(false);

    const submitContactForm = () => {
        return new Promise((resolve, reject) => {
            const pendingErrors = {};

            if (!contactState.email || !(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(contactState.email))) {
                pendingErrors.email = 'Please enter a valid email address';
            }
            //if (!contactState.message || contactState.message.length < 3) {
            //    pendingErrors.message = 'Please enter a message';
            //}
            if (!contactState.name || contactState.name.length < 2) {
                pendingErrors.name = 'Please enter a name';
            }
            if (!contactState.company || contactState.company.length < 2) {
                pendingErrors.company = 'Please enter a company name';
            }
            if (contactState.phone && !(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(contactState.phone))) {
                pendingErrors.phone = 'Please enter a valid phone number';
            }

            if (Object.keys(pendingErrors).length) {
                setErrors(pendingErrors);
                reject();
            } else {
                // Clear out any old errors
                if (Object.keys(errors).length) {
                    setErrors({});
                };

                contactState['form-name'] = FORM_NAME;

                if (typeof window !== "undefined") {
                    contactState['from-page'] = window.location.pathname;
                }

                // submit form
                setIsSubmittingForm(true);
                fetch('/', {
                    method: 'POST',
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: new URLSearchParams(contactState).toString(),
                })
                .then(() => {
                    setContactState(defaultContactState);
                    setShowSuccessSnackbar(true);
                    setHasSubmittedForm(true);
                    setIsSubmittingForm(false);
                    resolve();
                })
                .catch(e => {
                    setIsSubmittingForm(false);
                    alert('An unexpected error occurred. Please try again.');
                    console.log(e);
                    reject();
                });
            }

        });
    }

    const updateContactState = (updates) => {
        setContactState({
            ...contactState,
            ...updates,
        });
    };

    return (
        <ContactContext.Provider value={{
            ...contactState,
            errors,
            hasSubmittedForm,
            isSubmittingForm,
            setErrors,
            submitContactForm,
            updateContactState,
        }}>
            {children}
            <Snackbar
                autoHideDuration={6000}
                open={showSuccessSnackbar}
                onClose={() => setShowSuccessSnackbar(false)}
                TransitionComponent={SlideTransition}
            >
                <Alert severity="success" variant="filled">
                    Thank you! Your message has been sent.
                </Alert>
            </Snackbar>
        </ContactContext.Provider>
    );
};

export default ContactContext;