module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SpeedyDock","short_name":"SpeedyDock","start_url":"/","background_color":"#181c1a","theme_color":"#1d5892","display":"standalone","icon":"src/images/speedy-ico.png","icon_options":{"purpose":"maskable any"},"prefer_related_applications":true,"related_applications":[{"platform":"play","url":"https://play.google.com/store/apps/details?id=com.DevTonics.SpeedyDock","id":"com.DevTonics.SpeedyDock"},{"platform":"itunes","url":"https://apps.apple.com/us/app/speedydock/id1124648688"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3abd290ad94b4c6cfa0008676cbf21d0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-1CRCLHMYY0"],"head":false,"anonymize":false,"respectDNT":true,"pageTransitionDelay":200,"defer":true,"cookieDomain":"www.speedydock.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.speedydock.com","noTrailingSlash":true,"noHash":true,"noQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
